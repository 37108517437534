import {
    convertMapToList,
    DEFAULT_STATUS_KEY,
    StatusInfo,
} from 'src/components/EntityStatusInfo/EntityStatusInfo';
import { CssColors } from 'src/styles/CssVariables';

export const AgentApplicationStatusInfoMap: Record<string, StatusInfo> = {
    ON_KYC: {
        color: CssColors.mainGreen,
        borderColor: CssColors.mainGreen,
        title: 'On KYC',
        backgroundColor: CssColors.mainGreen,
    },
    VERIFICATION: {
        color: CssColors.mainGreen,
        borderColor: CssColors.mainGreen,
        title: 'Verification',
        backgroundColor: CssColors.mainGreen,
    },
    UPDATE_REQUESTED: {
        color: CssColors.mainGreen,
        borderColor: CssColors.mainGreen,
        title: 'Update requested',
        backgroundColor: CssColors.mainGreen,
    },
    UNDERWRITING: {
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        title: 'Underwriting',
        backgroundColor: CssColors.mainGray,
    },
    DECLINED: {
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        title: 'Declined',
        backgroundColor: CssColors.mainRed,
    },
    CANCELED: {
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        title: 'Canceled',
        backgroundColor: CssColors.mainRed,
    },
    APPROVED: {
        color: CssColors.mainGreen,
        borderColor: CssColors.mainGreen,
        title: 'Approved',
        backgroundColor: CssColors.mainGreen,
    },
    DISBURSED: {
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        title: 'Down payment collected',
        backgroundColor: CssColors.mainGray,
    },
    ACCOUNT_VERIFICATION: {
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        title: 'Acoount verification',
        backgroundColor: CssColors.mainGray,
    },
    ERROR: {
        title: 'Error',
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        backgroundColor: CssColors.mainRed,
    },
    [DEFAULT_STATUS_KEY]: {
        color: '#91796c',
        borderColor: '#F4F0DF',
        title: '',
        backgroundColor: '#F4F0DF',
    },
};

export const getAgentApplicationStatuses = () => {
    return convertMapToList(AgentApplicationStatusInfoMap);
};
