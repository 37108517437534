/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
import Components from 'formiojs/components/Components';
import intlTelInput from 'intl-tel-input';

const PhoneComponent = (Components as any).components.phoneNumber;

class PhoneField extends (PhoneComponent as any) {
    getGlobalPhoneMask() {
        return this.root.data?.env?.phoneMask || '';
    }

    initIntTelInput(inputElement: Element) {
        if (inputElement) {
            const intInput = intlTelInput(inputElement, {
                separateDialCode: true,
                autoInsertDialCode: true,
                utilsScript:
                    'https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js',
                autoPlaceholder: 'aggressive',
                nationalMode: false,
                initialCountry: 'zm',
            });

            this.intInp = intInput;
        }
    }

    intInp: any;

    setValue(value: string, flags: any) {
        if (typeof value !== 'string') return '';

        this.redraw();
        const formattedValue =
            value?.includes('+') || value === '' ? value : `+${value}`;

        const inputElement = this.refs.input?.[0];

        const res = super.setValue(formattedValue, flags);

        this.initIntTelInput(inputElement);

        return res;
    }

    getValueAt(index: number) {
        if (!this.isMultipleMasksField) {
            const value = super.getValueAt(index);
            const valueMask = this.component.inputMask;
            const { displayMask } = this.component;

            // If the input has only the valueMask or the displayMask is the same as the valueMask,
            // just return the value which is already formatted
            if ((valueMask && !displayMask) || displayMask === valueMask) {
                return this.intInp.getNumber();
            }

            // If there is only the displayMask, return the raw (unmasked) value
            if (displayMask && !valueMask) {
                return this.unmaskValue(value, displayMask);
            }

            if (this.refs.valueMaskInput?.mask) {
                this.refs.valueMaskInput.mask.textMaskInputElement.update(
                    value,
                );

                return this.refs.valueMaskInput?.value;
            }

            return value;
        }
        const textInput = this.refs.mask ? this.refs.mask[index] : null;
        const maskInput = this.refs.select ? this.refs.select[index] : null;

        return {
            value: textInput ? textInput.value : undefined,
            maskName: maskInput ? maskInput.value : undefined,
        };
    }

    attach(element: Element) {
        this.loadRefs(element, {
            input: 'multiple',
            component: 'single',
            element: 'single',
        });

        (this as any).addEventListener(
            (this as any).refs.element,
            'click',
            (event: any) => {
                event.preventDefault();
                this.triggerChange();
                this.updateValue(this.getValue());
            },
        );

        return super.attach(element);
    }
}

export default PhoneField;
